// InfoCard.js
import React from "react";
import "./info-card.css";

//type: info, danger, warning, success

const InfoCard = ({ title, type, children }) => {
  let titleClass = "";
  let cardClass = "";

  switch (type) {
    case "danger":
      titleClass = "danger";
      cardClass = "danger-bg";
      break;
    case "info":
      titleClass = "info";
      cardClass = "info-bg";
      break;
    case "success":
      titleClass = "success";
      cardClass = "success-bg";
      break;
    case "warning":
      titleClass = "warning";
      cardClass = "warning-bg";
      break;
    default:
      titleClass = "info";
      cardClass = "info-bg";
  }

  return (
    <div className={`info-card ${cardClass}`}>
      <h6 className={`title ${titleClass}`}>{title}</h6>
      {/* add a blank first line of child because it formats differently */}
      <p className="message">{children}</p>
    </div>
  );
};

export default InfoCard;
